.footerBody{
    /* background-image: url('https://images.unsplash.com/photo-1446776653964-20c1d3a81b06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80');
    background-repeat: no-repeat;
    /* border: 2px solid red; */
    /* background-size: cover; */
    background-color: black;
}
.location{
    padding-top: 10px;
    text-align: center;
    color: white;
    font-weight: bold;
}
.bottom{
    padding: 20px;
    text-align: center;
    color: white;
    font-weight: bold
}
.top{
    text-align: center;
    color: white;
    font-weight: bold
}
