
nav {
  margin: 0;
  padding: 0;
  background-color: #000;
}
 .menu {
   margin-top: 2rem;
 }
#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
  /* border: 2px red solid; */

}


nav:after {
  content: "";
  display: table;
  clear: both;
}
 
nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  /* top: 43px; */
}
 
nav ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  /* background-color: #254441; */
  background-color: #000;
}
 
nav a {
  display: block;
  padding: 0 20px;
  color: #FFF;
  /* font-size: 20px; */
  font-size: 17px;
  line-height: 60px;
  /* line-height: 55px; */
  text-decoration: none;
}
 
nav ul li ul li:hover { background: #000000;}
nav a:hover { background-color: #000000; color: white; }
 
nav ul ul {
  display: none;
  position: absolute;
  top: 60px;
  z-index: 1;
}
 
nav ul li:hover > ul { display: inherit;}
nav ul li:hover > ul >li>a:hover{color: red;transition: 0.3s ease-in;}
 
nav ul ul li {
  /* width: 170px; */
  width:214px;
  float: none;
  display: list-item;
  position: relative;
}
 
nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}
 
li > a:after { content: ''; }
.toggle, [id^=drop] {
 display: none;
}
li > a:only-child:after { content: ''; }

/*Responsive Styles*/


@media all and (max-width : 768px) {
#logo {
  width: 76%;
}

nav ul li:hover > ul {
  display: block;
}
 
nav { margin: 0; }
.toggle + a,
 .menu { display: none; }
.toggle {
  display: block;
  cursor: pointer;
  /* background-color: #254441; */
  /* background-color: #000; */
  padding: 0 20px;
  color: #FFF;
  font-size: 20px;
  line-height: 60px;
  text-decoration: none;
  border: none;
}
 
.toggle:hover { background-color: #000000; }

[id^=drop]:checked + ul { display: block; }
 
nav ul li {
  display: block;
  width: 100%;
}

nav ul ul .toggle,

 nav ul ul a { padding: 0 40px; }
 
nav ul ul ul a { padding: 0 80px; }

nav a:hover,

 nav ul ul ul a { background-color: #000000; }

nav ul li ul li .toggle,

 /* nav ul ul a { background-color: #212121; } */

nav ul ul {
  float: none;
  position: static;
  color: #ffffff;
}

nav ul ul li:hover > ul,

nav ul li:hover > ul { display: none; }

 nav ul ul li {
  display: block;
  width: 100%;
}

nav ul ul ul li { 
   position: static;
}
}
 
@media all and (max-width : 330px) {
nav ul li {
  display: block;
  width: 94%;
}
}

