.wrapper{
    background-color: #000;
    height: 59px;
    background-color: transparent;
    /* position: relative;
    z-index: 100; */
    /* display: none; */

}
.header-list{
    display: flex;
    list-style: none;
    justify-content: flex-end;
    /* border: 2px solid black; */
    padding: 13px;
}
.nav-menues{
    font-size: 22px;
    margin:2px 24px;
    text-decoration:none;
    color: #eee;
}
.nav-menues:hover{
    color: white;
}
@media screen and (max-width:960px) {
    .wrapper{
        display: none;
        /* flex-direction: column; */
        font-size: 16px;
        margin: 2px 12px;

    }
}
