.loading {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  /* border-bottom: 5px solid rgba(0, 0, 0, 0.719); */
  border-bottom: 5px solid white;

  border-radius: 50%;

  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}
